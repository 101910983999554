
import React from 'react';
import Text from "../UI/Text";
import List from "../UI/List";
import LI from "../UI/List/Item";
import Twitter from "../../assets/img/icons/x.png";
import "../componant.css";

function Footer() {
    return (
      <footer className="footer-area">
        <div className="container-fluid">
          <div className="row">
             
            <div className="col-md-4 col-sm-12 copy-right">
              <Text classes="copyright-txt">
                &copy; {new Date().getFullYear()} Yethi. All Rights Reserved.
              </Text>
            </div>

            <div className="col-md-4 col-sm-12 text-center d-flex align-items-center justify-content-center">
              <a href="/privacy-policy" className="text-white">
                Privacy Policy
              </a>{" "}
              <span className="text-white mx-2">|</span>
              <a href="/terms-&-conditions" className="text-white">
                Terms & Conditions
              </a>
            </div>

            <div className="col-md-4 col-sm-12 footer-social ">
              <List classes="widget-list d-flex gap-3">
                <LI>
                  <a
                    href="https://twitter.com/YethiHQ"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="" style={{width:'12px'}} />
                  </a>
                </LI>
                <LI>
                  <a
                    href="https://www.linkedin.com/company/yethi-consulting/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin text-white"></i>
                  </a>
                </LI>
                <LI>
                  <a
                    href="https://www.youtube.com/channel/UCfZc6qrmGuYuOyK699uHn7g"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <i className="fa fa-youtube text-white"></i>
                  </a>
                </LI>
                <LI>
                  <a
                    href="https://www.facebook.com/YethiConsulting/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook text-white"></i>
                  </a>
                </LI>
                <LI>
                  <a
                    href="https://www.instagram.com/lifeatyethi/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram text-white"></i>
                  </a>
                </LI>
              </List>
            </div>
          </div>
        </div>
      </footer>
    );
}

export default Footer;