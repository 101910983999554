import React from 'react';
import {Link} from "react-router-dom";

function NavbarItem(props) {
    return(
        <>
            <ul className="main-menu nav">          
                <li className='has-submenu'>
                    <Link to={`#`}>Products</Link>
                        <ul className="submenu-nav">
                            <li><Link to={`/tenjin-enterprise`}>Tenjin Enterprise</Link></li>
                            <li><Link to={`/tenjin-online`}>Tenjin Online</Link></li>
                            <li><Link to={`/tenjin-vault`}>Tenjin Vault</Link></li>
                            <li><Link to={`/tenjin-test-manager`}>Tenjin Test Manager</Link></li>
                            <li><Link to={`/tenjin-ai`}>Tenjin AI</Link></li>
                        </ul>
                </li>

                <li className='has-submenu'>
                    <Link to={`#`}>Industry</Link>
                        <ul className="submenu-nav">
                            <li><Link to={`/banking`}>Banking</Link></li>
                            <li><Link to={`/payments`}>Payments</Link></li>
                            {/* <li><Link to={`/`}>Insurance</Link></li> */}
                        </ul>
                </li>

                <li className='has-submenu'>
                    <Link to={`#`}>Services</Link>
                        <ul className="submenu-nav">
                            <li><Link to={`/consulting`}>Consulting</Link></li>
                            <li><Link to={`/testing-services`}>Testing Services</Link></li>
                            <li><Link to={`/tcoe`}>TCOE (Managed Services)</Link></li>
                            {/* <li><Link to={`/`}>Integeration & Implementation</Link></li> */}
                        </ul>
                </li>

                <li className='has-submenu'>
                    <Link to={`#`}>Practices</Link>
                        <ul className="submenu-nav">
                            <li><Link to={`/core-banking`}>Core Banking</Link></li>
                            <li><Link to={`/practices/payments`}>Payments</Link></li>
                            <li><Link to={`/transaction-banking`}>Transactional Banking</Link></li>
                            <li><Link to={`/lending`}>Lending</Link></li>
                            <li><Link to={`/digital-banking`}>Digital Banking</Link></li>
                            {/* 
                            
                            <li><Link to={`/`}>Wealth Management</Link></li>
                            <li><Link to={`/`}>GRC</Link></li>
                            <li><Link to={`/`}>Trade & Treasury</Link></li> */}
                        </ul>
                </li>

                <li>
                    <Link to={`/about-us`}>About Us</Link>
                </li>

                <li className='has-submenu'>
                    <Link to={`/insights`}>Insights</Link>
                        <ul className="submenu-nav">
                            <li><Link to={`/news`}>News & Events</Link></li>
                            <li><a href='https://blog.yethi.in/'>Blogs</a></li>
                            <li><Link to={`/resources`}>Resources</Link></li>
                        </ul>
                </li>

                <li>
                    <Link to={`/careers`}>Careers</Link>
                </li>

                <li>
                    <Link to={`/partner`}>Partners</Link>
                </li>
            </ul>      
        </>     
    )
}

export default NavbarItem